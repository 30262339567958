@import 'src/styles';

.header {
  width: 100%;
  padding: 0 16px;
  position: relative;
  z-index: 2;

  .top_light {
    width: 100%;
    height: 320px;
    background: radial-gradient(50% 50% at 50% 50%, #323858 0%, #101D23 100%);
    position: absolute;
    top: -18vh;
    left: 0;
    z-index: -1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0vh;
    left: 0;
    width: 100%;

    @include map-styles($manageHeaderBgStyles);
  }

  .title {
    margin-bottom: 10px;
    font-family: $managePrimaryFontFamily;
    font-style: normal;
    font-weight: $manageBenefitsTitleWeight;
    font-size: $manageHeaderTitleFontSize;
    line-height: 1.28;
    color: $manageTitleColor;
    white-space: break-spaces;

    &.top_gap {
      padding-top: 6vh;
    }
  }

  .subtitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $manageHeaderSubtitleFS;
    line-height: 1.28;
    color: $manageSubtitleColor;
    letter-spacing: normal;
  }
}

.back_button {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 10px;
  z-index: 3;

  svg {
    path {
      fill: $manageBackButtonColor;
      stroke: $manageBackButtonColor;
    }
  }
}