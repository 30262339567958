@import 'src/styles';

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  .label {
    display: flex;
    font-size: $manageReasonFS;
    line-height: $fontSize24;
    font-family: $manageReasonLabelFF;
    color: $manageReasonColor;
    width: 100%;

    &.checked {
      font-weight: 600;
      color: $manageAccentColor;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox__wrapper {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }

    .checkbox__mark {
      margin-top: 2px;
      height: 20px;
      width: 20px;
      border: 1px solid #B8B9C3;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: $transition;

      &::after {
        content: "";
        position: absolute;
        display: none;
        width: 12px;
        height: 12px;
        background: $manageCheckboxChecked;
        border-radius: 50%;
      }
    }
  }

  input:checked~.checkbox__mark {
    background-color: transparent;
    border: 1px solid $manageCheckboxChecked;
  }

  input:checked~.checkbox__mark::after {
    display: block;
  }
}