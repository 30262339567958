@import "src/styles";

.wrap {
  background-color: $manageBg;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .terms_wrap {
    padding: 0 15px;
  }

  .plansBadge {
    background-color: $managePlansBadgeColor;
  }

  .close {
    position: absolute;
    top: 22px;
    right: 10px;
    padding: 0 10px;
  }

  .header {
    padding: 0 32px;

    button {
      left: 5px;
    }
  }

  .btnTitle {
    font-size: $fontSize22;
  }

  .description {
    padding: 0 16px;
  }

  .policy {
    padding: 0 16px;
  }
}
