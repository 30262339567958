@import "src/styles";

@mixin text {
  color: $termsTextColor !important;
  font-size: $fontSize11 !important;
  font-weight: $fwn !important;
}

.terms_block {
  position: relative;

  .policy {
    display: block;

    &.error * {
      color: $termsErrorTextColor !important;
    }
  }

  .policy p,
  a {
    @include text;
    margin-bottom: 0;
    text-align: left;
  }

  .policy a {
    font-weight: $fwb !important;
  }

  span {
    @include text;
    margin-top: 0;
  }

}