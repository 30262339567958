@import "src/styles";

@mixin text{
  font-size: $fontSize9;
  font-weight: $fwn;
  line-height: 1.54;
  letter-spacing: 0.33px;
  color: $graySpecial;
}

.root {
  margin-top: 16px;
  @include text;

  a {
    text-decoration: underline;
    color: inherit;
  }

  &.short {
    color: $gray;
    font-size: $fontSize12;
    letter-spacing: 0px;
  }

  .descriptor {
    text-align: center;
  }

  .terms_icon_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;

      path {
        stroke: $graySpecial;
      }
    }

    p {
      display: block;
      text-align: left;
      width: 100%;
    }
  }

  .terms {
    display: flex;
    justify-content: center;

    .link {
      font-weight: 700;
    }
  }

  footer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}