@import "src/styles";

.modal {
  display: block;
  font-family: "proxima-nova", sans-serif;

  .title {
    text-align: left;
    font-family: inherit;
    margin-bottom: 16px;
    color: $textColor;
    display: flex;
    align-items: center;

    svg {
      width: 65px;
      margin-right: 12px;
      vertical-align: text-top;
    }
  }

  .form {
    width: 100%;

    .input {
      @include input-style;
      padding: 8px;
      margin-bottom: 16px;
      font-family: inherit;
      color: $textColor;
      caret-color: $textColor;

      &:user-invalid {
        border-color: $colorError;
      }
    }

    .btn {
      background: $buttonBackground;
      border-radius: 4px;
      max-width: 100%;
      height: 55px;

      span {
        font-family: "proxima-nova", sans-serif;
        font-size: 20px;

        svg {
          vertical-align: bottom;
          margin-right: 8px;
        }
      }
    }
  }
}
