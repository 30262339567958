@import "src/styles";

.wrap {
  padding: 15px 15px 20px;

  footer {
    margin-top: 0;
  }

  .title {
    padding-left: 20px;
    font-size: $fontSize26;
    line-height: $fontSize26;
    margin-bottom: 15px;
  }

  .subtitle {
    color: $manageMainColor;
    margin-bottom: 20px;
    color: var(--manageSubTextColor);
    font-size: $fontSize16;
    opacity: 0.8;
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bottom_text {
      color: var(--manageSubTextColor);
      margin-bottom: 10px;
      text-align: center;
      line-height: $fontSize22;
      font-size: $manageHeaderSubtitleFS;

      b {
        font-weight: $fwstb;
      }
    }

    .bottom_button {
      margin-top: 50px;
    }


  }

  .scroll_indicator {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(16, 29, 35, 0.00) 0%, rgba(16, 29, 35, 0.80) 41.5%, #101D23 100%);
    color: #FAF6E8;
    padding: 20px 30px 30px;
    transition: opacity 200ms;
    text-align: center;
    font-size: $fontSize15;
    font-weight: $fwb;
    line-height: $fontSize20;

    &.hidden {
      opacity: 0;
    }
  }
}