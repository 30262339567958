@import 'src/styles';
@import "../../../styles/mixins.module.scss";

@mixin title {
  color: $manageSubtitleColor;
  text-align: center;
  font-family: $fontFamilyPhilosopher;
  font-size: $fontSize22;
  font-weight: $fwstb;
  line-height: $fontSize26;
}

.wrap {
  padding: 15px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;

  &.v2 {
    padding: 15px 15px 20px;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .subtitle {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize20;
    line-height: $fontSize24;
    margin-bottom: 5px;
    color: $titleH1Color;
  }

  .description {
    color: $white;
    text-align: center;
    font-size: $fontSize15;
    font-weight: $fwn;
    line-height: $fontSize22;
    opacity: 0.8;
    margin-top: 15px;
  }

  .price_title {
    @include title;
    margin-bottom: 5px;
  }

  .text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize15;
    line-height: $fontSize20;
    color: $manageMainColor;
    letter-spacing: normal;
  }

  .bottom_button {
    font-size: $manageButtonFontSize;
    line-height: 1.1;
  }

  .reject_button {
    @include alterativeButton;

    margin-top: 10px;
  }

  .reject_button_title {
    @include alterativeButtonTitle;

    font-size: $fontSize20;
  }

  .price_text {
    color: rgba($manageMainColor, 0.8);

    &.alt_price_text {
      @include title;
      color: var(--primaryColor);

      s {
        color: rgba($color: $manageSubtitleColor, $alpha: .5);
      }
    }
  }

}