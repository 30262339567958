@import 'src/styles';

.defaultContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
  max-width: 325px;

  @media(min-height: 720px) {
    margin-top: 5vh;
  }

  .title {
    margin-left: -15px;
    margin-bottom: 10px;
    width: calc(100% + 30px);
    color: $manageTitleColor;
  }

  .label {
    color: $manageSubtitleColor
  }
}