@import "src/styles";

@mixin text {
  font-weight: $fwn;
  line-height: 1.54;
  color: $gray;
  font-size: $fontSize12;
  letter-spacing: 0px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  @include text;

  a {
    @include text;
    text-decoration: underline;
    color: inherit;
  }
}

footer {
  @include text;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}