@import 'src/styles';

.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 20px;
  margin-top: auto;

  .button_title {
    color: $manageAlternativeButtonColor;
    font-weight: $manageButtonFontWeight;
    font-size: $manageAlternativeButtonFS;
    text-shadow: none;
  }

  .description {
    color: $manageSubtitleColor
  }
}