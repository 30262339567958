@import "src/styles";
@import "../../../styles/mixins.module.scss";

.footer {
  width: 100%;
  text-align: center;
  margin-top: 0;

  .btn_title {
    font-size: $manageButtonFontSize;
  }

  .btn {
    margin-top: 40px;
    font-weight: $fwstb;

    @media (max-height: 620px) {
      margin-top: 0px;
    }
  }

  .bottom_button {
    @include alterativeButton;
    @include alterativeButtonTitle;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 55px;
    max-width: $maxButtonWidth;
    margin: 10px auto 0;
    font-family: $buttonFontFamily;
    text-align: center;
    border-radius: $buttonBorderRadius;
  }
}