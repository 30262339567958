@import 'src/styles';

.wrap {
  padding: 15px 15px 20px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;

  .title {
    font-size: $fontSize26;
    line-height: $fontSize26;
    margin-bottom: 60px;
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    .bottom_button_title {
      font-size: $manageButtonFontSize;
      line-height: 1.1;
    }

    .skip_button {
      font-size: $fontSize16;
      font-weight: $fwb;
      line-height: $fontSize22;
      margin-top: 15px;
    }

    .description {
      color: var(--manageSubTextColor);
      text-align: center;
      font-size: $fontSize12;
      font-weight: $fwn;
      line-height: $fontSize18;
      opacity: 0.6;
      margin-top: 10px;
    }
  }

}