@import 'src/styles';

.wrap {
  position: relative;
  padding: 15px 15px 0;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $manageBg;
  min-height: 100svh;

  .reasons {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .header {
    padding: 0 15px 30px;
  }

  .title {
    font-size: $fontSize26;
  }
}

.bottom {
  z-index: 10;
  position: sticky;
  left: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  .bottom_button {

    &:disabled {
      background: $manageReasonButtonDisabledColor;
      box-shadow: none;
      opacity: 1;

      .button {
        text-shadow: none;
        color: $manageReasonButtonDisabledTextColor;
      }
    }
  }

  .button {
    font-size: $manageButtonFontSize;
    line-height: 1.1;
    font-weight: $manageButtonFontWeight;
  }
}