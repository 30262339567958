@import 'src/styles';
@import "../../../styles/mixins.module.scss";

.dontMissWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0;
  min-height: 100svh;

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;

    .goToAppButtonTitle {
      font-size: $manageButtonFontSize;
      font-weight: $manageButtonFontWeight;
      color: $manageReasonButtonDisabledTextColor;
    }

    .cancelButton {
      @include alterativeButton;

      .cancelButtonTitle {
        @include alterativeButtonTitle;
      }
    }
  }
}