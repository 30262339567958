@import "src/styles";

.overlay {
  position: fixed;
  z-index: 1001;
  inset: 0;
  background: $manageOverlayBg;
  display: flex;

  .modal {
    background: $manageModalBg;
    border-radius: 30px;
    display: flex;
    align-items: center;
    outline: none;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    max-width: $maxWidthDefault;
    padding: 40px 20px 30px;
    bottom: -30px;
    height: fit-content;
    margin: auto;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;

      .title {
        font-family: $managePrimaryFontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: $manageFeaturesTitleFontSize;
        line-height: $fontSize28;
        margin-bottom: 10px;
        white-space: pre-line;
      }

      .text {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: $mamangeSuccessModalTextFS;
        letter-spacing: normal;
        line-height: $fontSize22;
        color: $manageMainColor;
        white-space: break-spaces;
      }
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;

      svg {
        path {
          fill: $manageModalCloseIcon;
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .button__title {
        font-style: normal;
        font-weight: 700;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
        font-size: $fontSize22;
        line-height: $fontSize26;
        color: $white;
      }
    }
  }
}