@import "src/styles";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100svh;
  padding: 36px 30px 20px;
  max-width: $maxWidthDefault;
  margin: 0 auto;
  text-align: center;
  color: $terminateTextColor;
  background: $terminateBg;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  max-width: 263px;
}

.texts {
  width: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_title {
    color: $terminateConfirmFeatureTitleColor;
    font-size: $terminateConfirmFeatureTitleSize;
    font-weight: $fwstb;
    line-height: 1.3;
    margin-top: 16px;

    @media (max-height: 650px) {
      font-size: $fontSize24;
      line-height: 1;
    }
  }

  &_text {
    width: 90%;
    font-size: $fontSize16;
    line-height: 1.38;
    margin-top: 10px;

    @media (max-height: 650px) {
      font-size: $fontSize14;
      line-height: 1.2;
    }

    b {
      color: $white;
      font-weight: $fwsb;
    }
  }
}

.bottom {
  width: 100%;
}