@import 'src/styles';

@mixin text {
  font-size: $fontSize14;
  color: $policyCheckBoxTextColor;
  line-height: 1.5;
}

.confirmation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;

  &.with_checkbox {
    .checkbox {
      float: left;
      margin-right: 10px;
      position: relative;
      top: 4px;
    }

    .text {
      display: inline;
    }

    a {
      text-decoration: underline;
    }
  }

  .text {
    @include text;
    font-family: var(--textFontFamily);
    margin-bottom: 5px;
    text-align: center;
  }

  .link {
    @include text;
    font-family: var(--textFontFamily);
    display: inline-block;
    text-decoration: underline;
  }
}