@import 'src/styles';
@import '../../../styles/mixins.module.scss';

.button_wrap {
  position: relative;
  z-index: 11;
  bottom: 0;
  width: 100%;
  background-color: $fixedWrapperBgColor;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  transition: 200ms;
  padding: 20px;

  .link_button {
    margin-top: 15px;
    line-height: $fontSize22;
    font-size: $fontSize16;
    font-weight: $fwb;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .main_button_title {
    font-size: $manageButtonFontSize;
  }

  .bottom_button {
    @include alterativeButton;

    margin-top: 10px;
  }

  .bottom_button_title {
    @include alterativeButtonTitle;
  }

  &.static_buttons {
    background-color: transparent;
    padding: 0;
  }

  &.fix_button {
    position: fixed;

    .bottom_button {
      display: none;
    }
  }

  &.fix_link {
    .bottom_button {
      display: block;
    }
  }

  &.hidden_button_wrap {
    background-color: transparent;
    pointer-events: none;

    .main_button {
      pointer-events: auto;
    }

    .link_button {
      pointer-events: auto;
    }

    .bottom_button {
      display: block;
      pointer-events: auto;
    }
  }

  &.hidden_button_wrap_fix_link {
    background-color: transparent;
  }

  &.swap_buttons {
    flex-direction: column-reverse;

    .main_button {
      margin-top: 10px;
      @include alterativeButton;
    }

    .bottom_button {
      margin-top: 0;
      background: $buttonBackground;
      text-shadow: $buttonTitleShadow;
      font-size: $buttonFontSize;
      font-weight: $buttonFontWeight;
    }
  }
}

.gap {
  width: 100%;
  height: 128px;
}