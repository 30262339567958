@import "src/styles";

@mixin checkbox {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.checkboxWrapper {

  &.error {
    .checkboxSpan {
      border-color: $termsErrorTextColor;
    }
  }

  .checkboxInput {
    @include checkbox();

    &:checked {
      & + span {
        border-color: var(--primaryColor);
        background: transparent;
      }
    }
  }

  .checkboxSpan {
    @include checkbox();
    pointer-events: none;
    background: transparent;
    border: 1px solid $termsTextColor;
    position: relative;

    &::after {
      content: "";
      @include checkbox();
      position: absolute;
      background-repeat: no-repeat;
      top: -4px;
      left: -3px;
      background-image: url("./check.svg");
    }
  }
}